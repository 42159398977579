import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout/Layout";
import { Category } from "../components/category/Category";
import { DiscordBanner } from "../components/discord/DiscordBanner";
import EventDate from "../components/event-date/EventDate";

import Posts from "../components/posts/Posts";

import styles from "./index.module.scss";

export const query = graphql
`query SITE_INDEX_QUERY {
  site {
    siteMetadata {
      title
      description
      s3bucket
    }
  }
  siteBuildMetadata {
    buildTime(formatString: "YYYY-MM-DD")
  }
  posts: allMdx(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {published: {eq: true}, type: {eq: "post"}}}
    limit: 12
  ) {
    nodes {
      slug
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(width: 254, quality: 80, formats: [WEBP, JPG], layout: CONSTRAINED)
          }
        }
        title
        date(formatString: "MMM Do, YYYY")
        dateSimple: date(formatString: "YYYY-MM-DD")
        category
        excerpt
      }
    }
  }
  events: allMdx(
    sort: {fields: [frontmatter___startDate], order: ASC}
    filter: {
        fields: {
            due: {ne: "past"}
        }
        frontmatter: {
            published: {eq: true}
            type: {eq: "event"}
        }
    }
    limit: 3
  ) {
    dates: group(field: frontmatter___startDate) {
        fieldValue
        nodes {
            slug
            frontmatter {
                image {
                    childImageSharp {
                        gatsbyImageData(width: 254, quality: 80, formats: [WEBP, JPG], layout: CONSTRAINED)
                    }
                }
                title
                category
                date(formatString: "MMM D, YYYY")
                dateSimple: date(formatString: "YYYY-MM-DD")
                startDate(formatString: "MMM D, YYYY")
                startDateSimple: startDate(formatString: "YYYY-MM-DD")
                endDate(formatString: "MMM D, YYYY")
                endDateSimple: endDate(formatString: "YYYY-MM-DD")
                startTime
                endTime
                platform
                productUrl
                category
                type
            }
        }
    }
  }
  reviews: allMdx(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {published: {eq: true}, type: {eq: "review"}}}
    limit: 5
  ) {
    nodes {
      slug
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(width: 600, quality: 80, formats: [WEBP, JPG], layout: CONSTRAINED)
          }
        }
        media
        title
        category
      }
    }
  }
  podcasts: allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {frontmatter: {type: {eq: "podcast"}}}
    limit: 1
  ) {
    nodes {
      slug
      frontmatter {
        file {
          url
        }
        series {
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 600, quality: 80, formats: [WEBP, JPG], layout: CONSTRAINED)
            }
          }
        }
        excerpt
        episode
        season
        title
      }
    }
  }
}
`

const RootIndex = ({ data, location }) => {

    const podcast = data.podcasts.nodes[0];

    const lastBuild = data.siteBuildMetadata.buildTime;

    return (
        <Layout
            location={location}
            isHome
        >
            <div className={styles.content}>
                <div className={styles.posts}>
                    <h2
                        className={styles.subtitle}
                    >
                        Latest posts
                    </h2>

                    <Posts
                        data={data.posts}
                    />
                    
                    <p className={styles.more}>
                        <Link
                            to="/posts/1"
                            className={styles.link}
                        >
                            More posts...
                        </Link>
                    </p>
                </div>

                <div className={styles.secondary}>
                    {data.events.dates.length !== 0 &&
                        <div className={styles.events}>
                            <h2
                                className={styles.subtitle}
                            >
                                Upcoming events
                            </h2>

                            {data.events.dates.map(date => (
                                <div className={styles.eventGroup}>
                                    <EventDate
                                        date={date.fieldValue}
                                        className={styles.date}
                                        hideDay={true}
                                        isToday={Date.parse(lastBuild) === Date.parse(date.fieldValue)}
                                    />
                                    <div className={styles.eventList}>
                                        {date.nodes.map(({ frontmatter, slug }) => (
                                            <div 
                                                key={slug}
                                                className={styles.event}
                                            >
                                                <div className={styles.copy}>
                                                    <Category
                                                        className={styles.category}
                                                    >
                                                        {frontmatter.category}
                                                    </Category>
                                                    <Link
                                                        className={styles.postLink}
                                                        to={slug}
                                                    >
                                                        <h3 
                                                            className={styles.title}
                                                        >
                                                            {frontmatter.title}
                                                        </h3>
                                                        <p className={styles.meta}>
                                                            {frontmatter.platform && frontmatter.platform.length > 0 &&
                                                                <span className={styles.platforms}>[
                                                                    {frontmatter.platform.map(plat =>
                                                                        <span key={plat}>
                                                                            {plat}
                                                                        </span>
                                                                    )}
                                                                ]</span>
                                                            }
                                                            {frontmatter.startTime && frontmatter.type === "event" &&
                                                                <time className={styles.date} dateTime={`${frontmatter.startTime}`}>{frontmatter.startTime}</time>
                                                            }
                                                            {frontmatter.endTime && frontmatter.type === "event" &&
                                                                <React.Fragment>
                                                                    to <time className={styles.date} dateTime={`${frontmatter.endTime}`}>{frontmatter.endTime}</time>
                                                                </React.Fragment>
                                                            }
                                                        </p>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}

                            <p className={styles.more}>
                                <Link
                                    to="/events/1"
                                    className={styles.link}
                                >
                                    More events...
                                </Link>
                            </p>
                        </div>
                    }

                    <div className={styles.reviews}>
                        <h2
                            className={styles.subtitle}
                        >
                            Latest reviews
                        </h2>
                        <Link
                            to={data.reviews.nodes[0].slug}
                        >
                            <GatsbyImage
                                image={{...data.reviews.nodes[0].frontmatter.image.childImageSharp.gatsbyImageData, aspectRatio: 1.3}}
                                className={styles.reviewImage}
                                alt={data.reviews.nodes[0].frontmatter.title} />
                        </Link>
                    
                        {data.reviews.nodes.map(({ frontmatter, slug }) => (
                            <div 
                                key={slug}
                                className={styles.review}
                            >
                                <Category
                                    className={styles.category}
                                >
                                    {frontmatter.category}
                                </Category>
                                <Link
                                    className={styles.postLink}
                                    to={slug}
                                >
                                    <h3 
                                        className={styles.title}
                                    >
                                        {frontmatter.title}
                                        <span> [{frontmatter.media}]</span>
                                    </h3>
                                </Link>
                            </div>
                        ))}

                        <p className={styles.more}>
                            <Link
                                to="/reviews/1"
                                className={styles.link}
                            >
                                More reviews...
                            </Link>
                        </p>
                    </div>

                    <div className={styles.podcast}>
                        <h2
                            className={styles.subtitle}
                        >
                            Latest podcast
                        </h2>
                        <Link
                            to={podcast.slug}
                        >
                            <GatsbyImage
                                image={podcast.frontmatter.series.image.childImageSharp.gatsbyImageData}
                                className={styles.image}
                                alt={podcast.frontmatter.title} />
                        </Link>

                        <Category 
                            className={styles.category}
                            noLink
                        >
                            {podcast.frontmatter.series.title}
                        </Category>

                        <h3 className={styles.title}>
                            <Link
                                to={podcast.slug}
                            >
                                {podcast.frontmatter.season && podcast.frontmatter.season.length > 0 && podcast.frontmatter.season !== "0" &&
                                    <span>S{podcast.frontmatter.season}</span>
                                }
                                {podcast.frontmatter.episode && podcast.frontmatter.episode.length > 0 && podcast.frontmatter.season !== "0" &&
                                    <span>E{podcast.frontmatter.episode}: </span>
                                }
                                {podcast.frontmatter.title}
                            </Link>
                        </h3>

                        <audio className={styles.audio} controls>
                            <source src={`${data.site.siteMetadata.s3bucket}${podcast.frontmatter.file.url}`} type="audio/mpeg" />
                            Your browser does not support the audio tag.
                        </audio>

                        <p className={styles.excerpt}>{podcast.frontmatter.excerpt}</p>

                        <p className={styles.more}>
                            <Link
                                to="/podcasts/1"
                                className={styles.link}
                            >
                                More podcasts...
                            </Link>
                        </p>
                    </div>
                    
                    <DiscordBanner />
                </div>
            </div>
        </Layout>
    );
}
            
export default RootIndex;