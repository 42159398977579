import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export const DiscordBanner = () => {
    return (
        <StaticQuery
            query={graphql`query DiscordBanner {
  link: site {
    siteMetadata {
      discordUrl
    }
  }
  fileName: file(relativePath: {eq: "discord-banner.png"}) {
    childImageSharp {
      gatsbyImageData(width: 440, quality: 100, formats: [WEBP, JPG], layout: CONSTRAINED)
    }
  }
}
`}
            render={data => (
                <a
                    style={{marginBottom: "2.5rem"}}
                    href={data.link.siteMetadata.discordUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <GatsbyImage
                        image={data.fileName.childImageSharp.gatsbyImageData}
                        style={{maxWidth: "440px", margin: "auto"}}
                        alt="Join the discussion on Discord" />
                </a>
            )}
        />
    );
}